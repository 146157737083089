import { Inject, Injectable } from '@angular/core';
import { COLLECTIONS, ENVIRONMENT } from '../core.constants';
import { Preference } from '../models/preference.model';
import { AuthService } from '../services/auth.service';
import { FirestoreService } from '../services/firestore.service';
import { Observable } from 'rxjs';
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  private collection = COLLECTIONS.USER_PREFERENCES;

  public myPreferences$: Observable<Preference> = this.auth.uid$.pipe(
    switchMap((uid) => this.firestore.getDocument<Preference>(this.collection, uid)),
    shareReplay(1),
  );

  public myLanguage$: Observable<string> = this.myPreferences$.pipe(map((pref) => pref.language));

  constructor(
    private firestore: FirestoreService,
    private auth: AuthService,
    @Inject(ENVIRONMENT) private environment: any,
  ) {}

  createOrUpdatePreferences(data: Partial<Preference>): Promise<void> {
    return this.auth.uid$
      .pipe(
        first(),
        switchMap((uid) => this.firestore.createOrUpdate(this.collection, uid, data)),
      )
      .toPromise();
  }
}
