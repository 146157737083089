import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, from } from 'rxjs';
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { COLLECTIONS } from '../core.constants';
import { Id, Role } from '../models/common.model';
import { User } from '../models/user.model';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { UserPreferencesService } from '@core/use-case-services/user-preferences.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private cachedUser: Record<Id, Observable<string>> = {};
  user$: Observable<User> = this.auth.uid$.pipe(
    switchMap((uid) => this.firestore.getDocument<User>(COLLECTIONS.USERS, uid)),
    map((user) => {
      user.imageId = user.imageId || '/assets/icon/default-avatar.png';
      return user;
    }),
    tap((user) => {
      Sentry.setUser({ email: user.email, id: user.id });
      Sentry.setContext('role', { roles: user.roles });
      this.userPreferencesService.myPreferences$.pipe(first()).subscribe();
    }),
    shareReplay(1),
  );

  uid$: Observable<Id> = this.auth.uid$;

  roles$: Observable<Role[]> = this.user$.pipe(
    map((u) => u.roles),
    shareReplay(1),
  );

  constructor(
    private firestore: FirestoreService,
    private userPreferencesService: UserPreferencesService,
    private translocoService: TranslocoService,
    private auth: AuthService,
  ) {}

  updateUserData(userData: Partial<User>): Promise<void> {
    return this.uid$
      .pipe(
        first(),
        switchMap((uid) => this.firestore.createOrUpdate(COLLECTIONS.USERS, uid, userData)),
      )
      .toPromise();
  }

  createUser(email: string, pass: string, userData?: any) {
    return from(this.auth.register(email, pass)).pipe(
      map((res) => res.user.uid),
      tap(async () => {
        const lang = this.translocoService.getActiveLang();
        await this.userPreferencesService.createOrUpdatePreferences({
          language: lang,
          emails: true,
          notifications: true,
        });
      }),
      switchMap((id) => this.firestore.create(COLLECTIONS.USERS, { id, email, ...userData })),
    );
  }

  createSocialLogin(userData: Omit<User, 'imageId'>) {
    return from(this.firestore.create(COLLECTIONS.USERS, userData));
  }

  getUserEmail(userId: string): Observable<string> {
    if (!this.cachedUser[userId]) {
      this.cachedUser[userId] = this.firestore
        .getDocument<User>(COLLECTIONS.USERS, userId)
        .pipe(map((res) => res.email));
    }
    return this.cachedUser[userId];
  }

  userExists(uid: string) {
    return this.firestore.exists(COLLECTIONS.USERS, uid);
  }
}
