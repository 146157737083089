import { InjectionToken } from '@angular/core';

const NAMESPACE = 'gt_';

export const COLLECTIONS = {
  USERS: 'users',
  USER_PREFERENCES: 'user_preferences',
  MASTERS: 'masters',
  MASTERS_SETTINGS_DOC: 'settings',
  EMAILS: 'emails',
  EMAILS_TEMPLATES: 'email_templates',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_TEMPLATES: 'notifications_templates',
  INSCRIPTIONS: NAMESPACE + 'inscriptions',
  EDITIONS: NAMESPACE + 'editions',
  EDITION_STAGES: NAMESPACE + 'edition_stages',
  MESSAGES: NAMESPACE + 'messages',
  RESERVATION_ITEMS: NAMESPACE + 'reservation_items',
  BOOKINGS: NAMESPACE + 'bookings',
  CONTACTS: NAMESPACE + 'contacts',
  PAYMENTS: NAMESPACE + 'payments',
};

export const ENVIRONMENT = new InjectionToken<any>('environment');
