import { Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from '@angular/fire/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { BehaviorSubject, Observable, OperatorFunction, filter, from, map, tap } from 'rxjs';

export interface AuthStatus {
  // Undefined while checking
  loggedIn: boolean | undefined;
  uid?: string;
}

const EMPTY_LOGGED = { loggedIn: undefined };

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authStatusSubject = new BehaviorSubject<AuthStatus>(EMPTY_LOGGED);
  public authStatus$ = this.authStatusSubject.asObservable();

  public isLoggedIn$: Observable<boolean> = this.authStatusSubject.pipe(
    filter((x) => x != null),
    map((v) => Boolean(v.loggedIn)),
  );

  /**
   * Returns loggedIn user id. After logout it does not change until next login.
   */
  public uid$: Observable<string> = this.authStatusSubject.pipe(
    filter((status) => status.loggedIn === true && status.uid !== undefined) as OperatorFunction<
      AuthStatus,
      Required<AuthStatus>
    >,
    map((v) => v.uid),
  );

  constructor(private auth: Auth) {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.authStatusSubject.next({ loggedIn: true, uid: user.uid });
      } else {
        this.authStatusSubject.next({ loggedIn: false });
      }
    });
  }

  login(email: string, pass: string) {
    this.authStatusSubject.next(EMPTY_LOGGED);
    return signInWithEmailAndPassword(this.auth, email, pass);
  }

  logout() {
    FirebaseAuthentication.signOut();
    return from(this.auth.signOut()).pipe(tap(() => this.authStatusSubject.next({ loggedIn: false })));
  }

  register(email: string, pass: string) {
    this.authStatusSubject.next(EMPTY_LOGGED);
    return createUserWithEmailAndPassword(this.auth, email, pass);
  }

  resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  updatePassword(newPassword: string) {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      return updatePassword(currentUser, newPassword);
    } else {
      console.error('Current user is null. Unable to update password.');
      return false;
    }
  }
}
