import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader, TranslocoModule, provideTransloco } from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/en';
import { environment } from '../environments/environment';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';

@Injectable({
  providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`./assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTranslocoLocale(),
    provideTransloco({
      config: {
        failedRetries: 0,
        availableLangs: ['es', 'en', 'fr', 'it', 'pt'],
        defaultLang: 'es',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class I18nModule {}
